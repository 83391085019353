<template>
  <No_data_to_show label="No Reports to show" />
</template>

<script>
import No_data_to_show from "@/components/no_data_to_show";
export default {
  name: "report_destructor",
  components: { No_data_to_show }
};
</script>

<style scoped></style>
